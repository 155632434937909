import styled from '@emotion/styled-base'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import Layout from '../../components/layout'
import withRoot from '../../withRoot'

const CreditContainer = styled('div')`
  margin-bottom: 16px;
`

const CreditsPage = ({ location: { pathname }}) => (
  <Layout title="Credits" pathname={pathname} upwards="/settings">
    <CreditContainer>
      <Typography variant="overline">Images</Typography>
      <Typography
        component="a"
        href="https://www.freepik.com/free-vector/collection-money-bags-with-coins-flat-design_1137701.htm"
      >
        Collection of money bags with coins in flat design Free Vector by
        freepik
      </Typography>
    </CreditContainer>
    <CreditContainer>
      <Typography variant="overline">Open Source Packages</Typography>
      <Typography component="a" href="https://gatsby.org">
        Gatsby.js
      </Typography>
      <Typography component="a" href="https://material-ui.org">
        Material UI
      </Typography>
      <Typography component="a" href="http://financejs.org">
        Finance.js
      </Typography>
      <Typography component="a" href="https://sarahdayan.github.io/dinero.js/">
        Dinero.js
      </Typography>
    </CreditContainer>
  </Layout>
)

export default withRoot(CreditsPage)
